exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-credentials-js": () => import("./../../../src/pages/credentials.js" /* webpackChunkName: "component---src-pages-credentials-js" */),
  "component---src-pages-funds-js": () => import("./../../../src/pages/funds.js" /* webpackChunkName: "component---src-pages-funds-js" */),
  "component---src-pages-hosting-js": () => import("./../../../src/pages/hosting.js" /* webpackChunkName: "component---src-pages-hosting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-model-manager-js": () => import("./../../../src/pages/model-manager.js" /* webpackChunkName: "component---src-pages-model-manager-js" */),
  "component---src-pages-portfolio-engineering-js": () => import("./../../../src/pages/portfolio-engineering.js" /* webpackChunkName: "component---src-pages-portfolio-engineering-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-process-automation-js": () => import("./../../../src/pages/process-automation.js" /* webpackChunkName: "component---src-pages-process-automation-js" */),
  "component---src-pages-reporting-js": () => import("./../../../src/pages/reporting.js" /* webpackChunkName: "component---src-pages-reporting-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-risk-management-js": () => import("./../../../src/pages/risk-management.js" /* webpackChunkName: "component---src-pages-risk-management-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-unsupported-browser-js": () => import("./../../../src/pages/unsupported-browser.js" /* webpackChunkName: "component---src-pages-unsupported-browser-js" */),
  "component---src-pages-workflow-js": () => import("./../../../src/pages/workflow.js" /* webpackChunkName: "component---src-pages-workflow-js" */)
}

